.custom-modal-content {
    width: 1000px; /* Increase the modal width */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
    padding: 20px; /* Add some padding for better aesthetics */
  }
  
.custom-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust the gap between rows */
    max-height: 60vh; /* Ensure modal body doesn't exceed viewport height */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }

  
  .form-row > * {
    flex: 1; /* Ensure all children take equal width */
    min-width: 0; /* Prevent items from overflowing container */
  }
  
/* ---------------------------------------------------------------------------------------------------- */



.form-row {
  display: flex;
  gap: 30px; /* Adjust the gap between columns */
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-left: 50px;
  margin-right: 50px;
   /* Allow wrapping to next line if necessary */
}

.form-row > * {
  flex: 1; /* Ensure all children take equal width */
  min-width: 0; /* Prevent items from overflowing container */
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between label and input fields */
}

.label-container {
  display: flex;
  gap: 20px; /* Space between labels */
  align-items: center;
}

.form-label {
  display: block;
  margin: 0;
  text-align: left;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between input fields */
}

.form-input {
  width: 10px; /* Shorter width for input fields */
  max-width: 10%; /* Ensure it doesn’t exceed the container width */
}

/* ------------------------------- */


/*--------student login------------*/

.form-container-directpage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row-directpage  {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  width: 100%; 
  max-width: 400px; 
  margin-bottom: 16px;
}

.form-row-directpage  > * {
  width: 100%;
}

.subject-input {
  width: 50% !important; 
}

.amount-input {
  width: 20% !important;
  
}

.payment-methods-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; 
}

.payment-method-button {
  width: 100%; 
  max-width: 200px; 
}

/* ------------------------------- */


.payment-method-button.active {
  background-color: #c9d3dc;
  color: rgb(0, 0, 0);
}

/*--------------------------------*/


.highlight-outline {
  border-color: red !important;
}
