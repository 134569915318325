.list-search-wrapper {

    .list-heading {
        // color: #ff6347; 
        color: #800080;
        margin-top: -10px;   
        margin-bottom: -25px; 
        margin-left: 40px;  
        font-size: larger; 
        font-style:oblique;   
      
      }

  .listandsearch__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    @include media-min-lg {
      flex-direction: row;
    }
    @include media-min-xl {
      gap: 2rem;
    }
  
    .list-wrapper {
      width: 100%;
  
      @include media-min-lg {
        width: calc(35% - 0.5rem); 
      }
  
      @include media-min-xl {
        width: calc(35% - 1rem); 
      }
  
      .box {
        height: 30rem;
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
  
        .header {
          h2 {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.6;
            color: #f44336;
          }
        }
  
        .content {
          flex-grow: 1;
          overflow-y: auto;
          padding: 1rem;
        }
      }
    }
  
    .search-wrapper {
      width: 100%;
  
      @include media-min-lg {
        width: calc(65% - 0.5rem); // Decreased width for large screens
      }
  
      @include media-min-xl {
        width: calc(65% - 1rem); // Decreased width for extra-large screens
      }
  
      .box {
        height: 30rem;
        display: flex;
        flex-direction: column;
  
        .header {
          h2 {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.6;
          }
        }
  
        .content {
          flex-grow: 1;
          overflow-y: auto;
        }
      }
    }
  
    .label-container {
      display: flex;
      align-items: center;
      width: 100px;
    }
    
    .input-container {
      display: flex;
      align-items: center;
      flex: 1;
    }
    
    .row {
      display: flex;
      gap: 16px;
    }
    
    .right-aligned {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%; 
    }
  
    .btn {
      display: inline-block;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 0.25rem;
      transition: background-color 0.3s ease;
    }
    
    .btn-primary {
      background-color: #007bff;
      color: #fff;
    }
    
    .btn-primary:hover {
      background-color: #0056b3;
    }
    
    
  }
  
  }
  
  
  