.sidebar-wrapper {
  position: fixed;
  top: 3.5rem;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--hrms-secondary-clr);
  z-index: 10;
  width: 15.625rem;
  border-right: 1px solid var(--hrms-border-clr-01);
  box-shadow: 0 8px 24px rgba(229, 228, 230, 0.4);
  @include media-min-xl {
    top: 0;
  }

  .logo-wrapper {
    display: none;
    @include media-min-md {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.625rem;
      padding: 0.75rem;
      border-bottom: 1px solid var(--hrms-border-clr-01);
      height: 3.5rem;
    }
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      color: var(--hrms-clr-03);
      text-align: center;
    }
  }
  .menu-items-wrapper {
    display: flex;
    flex-direction: column;
    // gap: 0.75rem;
    padding: 0.75rem 0rem;
    .menu-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
      border-left: 5px solid transparent;
      background-color: transparent;
      transition: all 0.4s ease-in-out;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        path {
          stroke: var(--htms-icon-clr-01);
        }
      }
      span {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        color: var(--hrms-clr-04);
      }
      &.active , &:hover{
        border-left: 5px solid var(--hrms-clr-02);
        background-color: #0000001a;
        svg {
          path {
            stroke: var(--hrms-clr-02);
          }
        }
        span {
          color: var(--hrms-clr-02);
        }
      }
    }
  }
}

.menu-scroll {
  overflow-y: auto; /* Enable vertical scrollbar */
  max-height: calc(100vh - 100px); /* Adjust max height as needed */
}

.dark {
  .sidebar-wrapper {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    .logo-wrapper {
      h2 {
        color: #fff !important;
      }
    }
    .menu-items-wrapper {
      .menu-item {
        &.active , &:hover{
          border-left: 5px solid var(--hrms-clr-02);
          background-color: #ffffff1a;
          svg {
            path {
              stroke: var(--hrms-clr-02);
            }
          }
          span {
            color: var(--hrms-clr-02);
          }
        }
      }
    }
  }
}
