@tailwind base;
@tailwind components;
@tailwind utilities;

.menu-items-wrapper {
    padding-top: 0px !important;
}

.report-heading,
.forgetPassword,
#logo {
    color: rgb(54, 162, 235) !important;
}

.report-heading {
    font-weight: bold;
}

#report-heading {
    margin: 10px 0px 10px 5px;
}

#add-user-btn,
#apply-btn,
#loginBackground,
.addIncidentDetailsBtn {
    background-color: rgb(54, 162, 235) !important;
}

#pagination span {
    background-color: rgb(54, 162, 235) !important;
}

.addIncidentDetailsBtn:hover {
    background-color: rgb(54, 162, 235) !important;
}

.data-\[focus-visible\=true\]\:outline-focus[data-focus-visible=true] {
    outline-color: rgb(54, 162, 235) !important;
}

.leavemenu-wrapper {
    padding-top: 25px !important;
}

.form-container {
    max-height: 950px;
    /* overflow-y: auto; */
}

.incidentSubmitBtn {
    /* background-color: rgb(255, 99, 132); */
    /* background-color: rgb(255, 206, 86); */
    background-color: rgb(54, 162, 235);
}

#pagination li:nth-child(3) {
    border-radius: 10px;
}

#add-user-btn:hover {
    background-color: rgb(54, 162, 235);
}

.admin {
    animation-name: blink;
    animation-duration: 1s;
    font-size: 20px;
    font-weight: bold;
    animation-timing-function: ease-in-out;
    color: rgb(54, 162, 235);
    animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.07);
    }

    100% {
        transform: scale(1);
    }
}

#selectConatainer {
    background-color: rgb(255, 255, 255);
    width: 350px;
}

.LandingLoginImage {
    transition: transform 0.2s ease-in-out;
}


.LandingLoginImage:hover {
    transform: scale(1.05);
}
